<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="ma-0 py-0">
        <v-card elevation="2">
          <v-breadcrumbs :items="items" divider="/"></v-breadcrumbs>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <base-material-card title="Expense Log" icon="mdi-apple-finder">
          <v-sheet>
            <v-toolbar-items class="d-flex align-center">
              <v-row>
                <v-col cols="12" lg="3" md="6" sm="6">
                  <v-text-field
                    :label="$vuetify.lang.t('$vuetify.search')"
                    append-icon="mdi-magnify"
                    clearable
                    v-model="search"
                    @click:append="handleSearch"
                    @click:clear="handleClear"
                  />
                </v-col>

                <v-col cols="12" lg="3" md="6" sm="6">
                  <v-select
                    :label="$vuetify.lang.t('$vuetify.selecttype')"
                    :items="selectItem"
                    :item-text="selectItem.name"
                    :item-value="selectItem.value"
                    return-object
                    clearable
                    @change="(item) => handleFinance(item)"
                  />
                </v-col>

                <v-col cols="12" lg="3" md="6" sm="6">
                  <v-autocomplete
                    :label="$vuetify.lang.t('$vuetify.selecttype')"
                    :items="category"
                    :item-text="(item) => item.name"
                    return-object
                    @change="(item) => handleType(item)"
                    clearable
                  />
                </v-col>

                <v-col cols="12" lg="3" md="6" sm="6">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    :nudge-top="30"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateRangeText"
                        :label="
                          $vuetify.lang.t('$vuetify.startDate') +
                          ' ~ ' +
                          $vuetify.lang.t('$vuetify.endDate')
                        "
                        prepend-icon=""
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="date = null"
                      />
                    </template>

                    <v-date-picker
                      v-model="dates"
                      range
                      @change="handleDate"
                      @input="dates.length == 2 ? (menu = false) : true"
                      class="ma-0 pa-0"
                      :locale="
                        this.$vuetify.lang.current == 'zhHans' ? 'zh-cn' : 'en'
                      "
                    />
                  </v-menu>
                </v-col>

                <v-col
                  cols="12"
                  class="d-flex justify-end"
                  v-if="!$vuetify.breakpoint.mobile"
                >
                  <v-btn
                    color="#f50057"
                    elevation="2"
                    @click="handleResetFilter"
                  >
                    <v-icon left>mdi-restart</v-icon>
                    Reset Filter
                  </v-btn>

                  <v-btn color="primary" @click="categoryDialog = true">
                    <v-icon left>mdi-plus</v-icon>
                    New Category
                  </v-btn>

                  <v-btn color="#3d5afe" elevation="2" @click="handleDialogAdd">
                    <v-icon left>mdi-account-plus</v-icon>
                    {{ $vuetify.lang.t("$vuetify.add") }}
                  </v-btn>
                </v-col>

                <v-col cols="12" class="d-flex justify-end" v-else>
                  <v-btn
                    color="#f50057"
                    elevation="2"
                    @click="handleResetFilter"
                  >
                    <v-icon>mdi-restart</v-icon>
                  </v-btn>

                  <v-btn color="primary" @click="categoryDialog = true">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>

                  <v-btn color="#3d5afe" elevation="2" @click="handleDialogAdd">
                    <v-icon>mdi-account-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar-items>
          </v-sheet>

          <ExpenseTable
            :headers="headers"
            :datas="datas"
            :tableLoading="tableLoading"
            :search="search"
            :data_table_options="data_table_options"
            @edititem="handleEdit"
            @deleteitem="handleDelete"
            @copyitem="handleCopy"
            @historyitem="handleHistory"
            @pageChange="handlePageChange"
            @go2Page="handleGo2Page"
            class="my-4"
          />
        </base-material-card>
      </v-col>
    </v-row>

    <DeleteDialog
      :dialogDelete.sync="dialogDelete"
      :deleteObj="obj"
      @handleDelete="handleDeleteConfirm"
    />

    <ExpenseCRUD
      :dialog.sync="dialog"
      :obj="obj"
      :category="category"
      @editEvent="handleEditConfirm"
    />

    <History
      :dialogHistory.sync="dialogHistory"
      :obj="obj"
      :dataType="dataType"
      :category="category"
    />

    <v-dialog persistent v-model="categoryDialog" width="1000">
      <v-card>
        <v-card-title class="font-weight-bold display-2">
          {{ $vuetify.lang.t("$vuetify.newCategory") }}
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="7">
              <v-sheet
                height="300px"
                style="border: 1px solid black; overflow-y: scroll"
              >
                <v-list>
                  <v-list-item-group v-model="selectCategory">
                    <span
                      v-for="(item, key) in cloneCategory"
                      :key="key"
                      class="d-flex align-center justify-center"
                    >
                      <v-list-item class="ml-4 mr-2 my-2">
                        {{ item.name }}
                      </v-list-item>

                      <v-btn
                        v-if="permissionCheck('del')"
                        color="red"
                        text
                        class="ml-2 mr-4"
                        @click="handleDeleteCategory(item)"
                      >
                        <v-icon>mdi-trash-can</v-icon>
                      </v-btn>
                    </span>
                  </v-list-item-group>
                </v-list>
              </v-sheet>
            </v-col>

            <v-col cols="5">
              <v-form lazy-validation ref="form" v-model="valid">
                <v-text-field
                  :rules="rules"
                  :label="$vuetify.lang.t('$vuetify.categoryName')"
                  v-model="categoryObj.name"
                  outlined
                />
                <v-textarea
                  height="100px"
                  :label="$vuetify.lang.t('$vuetify.note')"
                  v-model="categoryObj.note"
                  outlined
                />

                <v-spacer />

                <span class="d-flex justify-end">
                  <v-btn
                    color="green"
                    class="ma-0"
                    outlined
                    @click="handleAddCategory"
                  >
                    <v-icon left>mdi-plus</v-icon>
                    {{ $vuetify.lang.t("$vuetify.save") }}
                  </v-btn>
                </span>

                <span class="d-flex justify-end mt-3">
                  <span class="headline red--text">
                    Please click save to confirm operation
                  </span>
                </span>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pt-0 pb-6 pl-5">
          <!-- <v-btn color="red" outlined @click="handleCategoryCancel">
            {{ $vuetify.lang.t("$vuetify.cancel") }}
          </v-btn> -->
          <v-btn color="green" @click="handleCategoryConfirm">
            {{ $vuetify.lang.t("$vuetify.done") }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  feeTypesList,
  feeTypesUpsert,
  feeTypesDelete,
  feeLogList,
  feeLogDelete,
  feeLogUpsert,
} from "@/api/financial";
import { profilePicUpload } from "@/api/company";

const newSearch = () => {
  return {
    key: "",
    skip: 0,
    limit: 10,
    sell_company: "",
    type: "",
    bt: "",
    et: "",
    in_out: 0, // 1、收入 2、支出
  };
};

const newObj = () => {
  return {
    account_no: "", // 收款方\付款方账号
    address: "", // 收款\付款公司地址
    attach: [],
    bank_name: "", // 银行名称
    code: "", // 编号
    company: "", // 收款\付款公司
    contact: "", // 收款\付款方联系人
    currency: "USD",
    email: "", // 联系人邮箱
    gen_time: "",
    id: "",
    in_out: 1, // 1、收入 2、支出
    money: "",
    other_contact: "", // 其他联系人
    pay_time: "", // 支付日期
    phone: "", // 收款\付款放联系电话
    po: "",
    remark: "", // 备注
    type: "",
    type_name: "",
  };
};

const newCategory = () => {
  return {
    id: "",
    name: "",
    order: "",
    note: "",
    sell_company: "", // 销售主体
  };
};

const newTableOption = () => {
  return {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    totalItems: 0,
    footerProps: {
      itemsPerPageOptions: [10, 20, 30, 40, 50],
    },
  };
};

export default {
  name: "ExpenseLog",
  components: {
    ExpenseTable: () => import("@/components/financial/expenseTable"),
    DeleteDialog: () => import("@/components/deleteDialog"),
    ExpenseCRUD: () => import("@/components/financial/expenseCRUD"),
    History: () => import("@/components/financial/history"),
  },
  data() {
    return {
      menu: false,
      restart: false,
      dataType: "fee_log",
      dialogDelete: false,
      dialogHistory: false,
      categoryDialog: false,
      tableLoading: true,
      dialog: false,
      valid: false,
      selectCategory: "",
      search: "",
      categoryObj: newCategory(),
      searchForm: newSearch(),
      data_table_options: newTableOption(),
      obj: newObj(),
      datas: [],
      category: [],
      cloneCategory: [],
      dates: [],
      rules: [(v) => !!v || "this field is required"],
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.itemNo"),
          value: "itemNo",
          width: "80px",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.code"),
          value: "po",
          width: "150px",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.Amount"),
          value: "money",
          align: "start",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.currency"),
          value: "currency",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.fenLei"),
          value: "type_name",
          width: "200px",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.dateReceipt"),
          value: "pay_time",
          width: "110px",
          width: "150px",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.addTime"),
          value: "gen_time",
          width: "110px",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.company"),
          value: "company",
          align: "center",
          width: "150px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.contactDetails"),
          value: "contact",
          align: "center",
          width: "150px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.contactPhone"),
          value: "phone",
          width: "180px",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.comAddress"),
          value: "address",
          align: "center",
          width: "150px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.bankName"),
          value: "bank_name",
          align: "center",
          sortable: false,
          width: "150px",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.bank"),
          value: "bank_account",
          align: "center",
          width: "150px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.note"),
          value: "remark",
          width: "250px",
          align: "start",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "actions",
          sortable: false,
          align: "center",
          width: "150",
        },
      ],
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.bank") },
      ],
      selectItem: [
        {
          text: this.$vuetify.lang.t("$vuetify.categoryListAll"),
          value: "",
        },
        { text: this.$vuetify.lang.t("$vuetify.income"), value: 1 },
        { text: this.$vuetify.lang.t("$vuetify.expense"), value: 2 },
      ],
    };
  },
  methods: {
    getData() {
      this.datas = [];

      feeLogList(this.searchForm)
        .then((res) => {
          this.tableLoading = false;
          this.data_table_options.totalItems =
            res.total <= 10
              ? 1
              : Math.round(
                  Math.floor(res.total / 10) + (res.total % 10 == 0 ? 0 : 1)
                );

          if (res.items != null) {
            this.datas = [...res.items];

            this.datas.forEach((item, index) => {
              item.itemNo = index + 1;
            });
          } else {
            this.datas = [];
            this.$toast.info("No Data Found");
          }
        })
        .catch((err) => console.log(err));
    },
    getType() {
      this.category = [];
      this.cloneCategory = [];

      feeTypesList()
        .then((res) => {
          this.cloneCategory = [...res.items];
          res.items.forEach((p) => this.category.push(p));
        })
        .catch((err) => conole.log("err", err));
    },
    handleAddCategory() {
      if (this.$refs.form.validate()) {
        this.categoryDialog = false;

        feeTypesUpsert(this.categoryObj)
          .then(() => {
            this.getData();
            this.categoryObj = newCategory();
            this.resetValidation();
          })
          .catch((err) => console.log("Add Category Err", err));
      }
    },
    handleDeleteCategory(data) {
      feeTypesDelete(data.id)
        .then(() => {
          this.getData();
        })
        .catch((err) => console.log("Delete Category Err", err));
    },
    handleCategoryCancel() {
      this.categoryDialog = false;
      this.tempCategory = [];
      this.tempDeleteCategory = [];
      this.categoryObj = newCategory();
    },
    handleCategoryConfirm() {
      this.categoryDialog = false;
      this.resetValidation();
    },
    handleResetFilter() {
      this.dates = [];
      this.searchForm = newSearch();

      this.getData();
    },
    handleDialogAdd() {
      this.obj = newObj();
      this.dialog = true;
    },
    handleEdit(obj) {
      if (this.permissionCheck("modify")) {
        this.obj = obj;
        this.dialog = true;
      }
    },
    handleCopy(obj) {
      obj.id = "";
      this.obj = obj;
      this.dialog = true;
      this.getData();
    },
    handleHistory(obj) {
      this.obj = obj;
      this.dialogHistory = true;
    },
    handleDelete(obj) {
      this.dialogDelete = true;
      this.obj = obj;
    },
    handleDeleteConfirm(obj) {
      this.dialogDelete = false;

      feeLogDelete(this.obj.id)
        .then(() => {
          // this.datas.splice(this.datas.indexOf(obj), 1);
          this.getData();
        })
        .catch((err) => console.log("Expense Delete", err));
    },
    async handleEditConfirm(obj, files) {
      this.dialog = false;

      obj.type = this.category.filter((p) => p.name == obj.type_name)[0].id;

      const arr = [];
      var tempFile = [];

      if (obj.attach.length > 0)
        obj.attach.forEach((p) => tempFile.push(p.md5));

      if (files != undefined) {
        await files.forEach((p) => {
          arr.push(this.uploadFile(p));
        });

        await Promise.all(arr)
          .then((res) => {
            res.forEach((p) => tempFile.push(p));
          })
          .finally(() => {
            obj.attach = JSON.stringify(tempFile);
          });
      } else {
        obj.attach = JSON.stringify(tempFile);
      }

      await feeLogUpsert(obj)
        .then(() => this.getData())
        .catch((err) => console.log("Expense Error", err));
    },
    handleSearch() {
      if (this.search != null) {
        this.searchForm.key = this.search;
      } else {
        this.searchForm.key = this.search;
      }

      this.getData();
    },
    handleClear() {
      this.searchForm.key = "";

      this.getData();
    },
    handleFinance(item) {
      item != null
        ? (this.searchForm.in_out = item.value)
        : (this.searchForm.in_out = "");

      this.getData();
    },
    handleType(item) {
      this.tableLoading = true;

      item != null
        ? (this.searchForm.type = item.id)
        : (this.searchForm.type = "");

      this.getData();
    },
    handleDate() {
      this.searchForm.bt = Array.isArray(this.dates) ? this.dates[0] : "";
      this.searchForm.et = Array.isArray(this.dates) ? this.dates[1] : "";

      this.getData();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    handlePageChange(item) {
      this.searchForm.skip = item.itemsPerPage * (item.page - 1);

      this.getData();
    },
    handleGo2Page(item) {
      if (item != "" || item != 0) {
        this.data_table_options.page = item;
      } else {
        this.data_table_options.page = 1;
      }

      this.getData();
    },
    async uploadFile(file) {
      var arr = "";

      const fileForm = new FormData();
      fileForm.append("file", file);

      await profilePicUpload(fileForm)
        .then((res) => {
          arr = res.data.md5;
        })
        .catch((err) => console.log("Finanical File Error", err));

      return arr;
    },
  },
  mounted() {
    this.getData();
    this.getType();
  },
  computed: {
    dateRangeText: {
      get: function () {
        return this.dates.join(" ~ ");
      },
      set: function (value) {
        if (value == null) {
          this.searchForm = newSearch();

          this.getData();
        }
      },
    },
  },
  // watch: {
  //   selectCategory: function () {
  //     console.log("adss");
  //   },
  // },
};
</script>
